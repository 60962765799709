const state = {
  order_id: null,
  order: null,
}

const getters = {
  cartItemTotal: (state) => state.order && state.order.line_items ? state.order.line_items.map(item => Number(item.quantity)).reduce((prev, next) => prev + next) : 0
}

const actions = {
  getOrder: ({ commit }, order_id) => {
    fetch(`https://api.getblaked.com/api/checkout/getOrder?order_id=${ order_id }`)
    .then((response) => response.json())
    .then((data) => {
      
      commit('setOrderId', data.id);
      commit('setOrder', data);
    });
  },

  addLineItem: ({ commit, dispatch, state }, line_item) => {
    if (state.order_id) {
      let existingItem = state.order.line_items.find(x => x.catalog_object_id == line_item.variationId);

      if (existingItem) {
        dispatch('updateOrder', { uid: existingItem.uid, quantity: String(Number(existingItem.quantity) + 1) });
      } else {
        dispatch('updateOrder', { catalog_object_id: line_item.variationId, quantity: '1' });
      }
    } else {
      dispatch('createOrder', { catalog_object_id: line_item.variationId, quantity: '1' });
    }
  },

  // updateLineItem: ({ commit, dispatch }, line_item) => {
  // },

  removeLineItem: ({ commit, dispatch, state }, line_item) => {
    if (state.order_id) {
      let existingItem = state.order.line_items.find(x => x.catalog_object_id == line_item.catalog_object_id);
  
      let fields_to_clear = "line_items[" + existingItem.uid + "]";
  
      // console.log(fields_to_clear)
  
      if (existingItem) {
        dispatch('removeOrder', fields_to_clear);
      }
    }
  },

  createOrder: ({ commit }, line_items) => {
    console.log('CREATE ORDER');

    console.log(line_items);

    fetch('https://api.getblaked.com/api/checkout/createOrder', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ 
        line_items, 
      })
    })
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
      commit('setOrderId', data.id);
      commit('setOrder', data);
    });
  },

  updateOrder: ({ commit, state }, line_items) => {
    console.log('Update Order');

    let order = { version: state.order.version++, line_items: [line_items] };

    // order.line_items[0].discount = {
    //   type: 'FIXED_PERCENTAGE',
    //   percentage: 50
    // }

    console.log(order.line_items[0])

    fetch(`https://api.getblaked.com/api/checkout/updateOrder?order_id=${ state.order_id }`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ order })
    })
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
      commit('setOrder', data);
    });
  },

  removeOrder: ({ commit, state }, fields_to_clear) => {
    let order = { version: state.order.version++ }
  
    fetch(`https://api.getblaked.com/api/checkout/updateOrder?order_id=${ state.order_id }`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ order, fields_to_clear })
    })
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
      
      if(data.line_items) {
        commit('setOrder', data);
      } else {
        commit('deleteOrderId', data.id)
        commit('setOrder', null);
      }
    });
  },

  createCheckout: ({ commit }, order_id) => {
    console.log('CREATE ORDER');

    fetch(`https://api.getblaked.com/api/checkout/createCheckout?order_id=${ order_id }`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
      window.location.replace(data.checkout_page_url)
      // commit('deleteOrderId', data.id)
      // commit('setOrder', null);
    });
  }
}

const mutations = {
  setOrderId: (state, order_id) => {
    state.order_id = order_id;
    localStorage.setItem('order_id', order_id);
  },

  deleteOrderId: (state) => {
    state.order_id = null;
    localStorage.removeItem('order_id')
  },

  setOrder: (state, order) => {
    if (order) {
      state.order = order
    } else {
      state.order = {}
    }
  }
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
