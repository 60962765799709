<template>
  <div class="row hero" id="homepage-hero">
    <a name="homepage"></a>
    <div class="col-12 col-lg-6 offset-lg-1 d-flex flex-column py-5 my-lg-5 mb-5 justify-content-center align-items-center align-items-lg-start" style="position: relative; overflow: hidden;">
      <img src="../assets/get-blaked-logo.png" class="logo"/>
      <p class="montserrat-regular text-green py-3 text-center text-lg-start" style="font-weight: 500;">We've upped the chill factor with our exHEMPlary CBD infused cookies; 6oz of pure bliss 'n chill.</p>
      <div class="d-flex" style="position: relative;">
      <router-link to="/collections" class="blaked-btn py-2" >Shop Now</router-link>
      <img src="../assets/cbd_cookie.png" class="cbd-cookie d-block d-lg-none"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomepageHero',
  // data() {
  //   return {
  //     hover: false,
  //   }
  // }
}
</script>

<style lang="scss">
.hero {
  background-image: url("../assets/homepage-hero-mobile.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  // height: 50vh;

  @media(min-width: 992px) {
    background-image: url("../assets/homepage-hero-w-cookie.png");
  height: 60vh;
  }

  .logo {
    width: 260px;

    @media(min-width: 992px) {
      width: 480px;
    }
  }

  p {
    max-width: 500px;
  }

  .cbd-cookie {
    position: absolute;
    width: 120px;
    right: -70px;
    bottom: -50px;
  }

}


</style>