<template>
  <div class="marquee row w-100 px-0 mx-0">
    <div class="track d-flex align-items-center h-100">
      <div class="text montserrat-small">&nbsp;All orders shipped on Monday following order     •     Contains 100 mg CBD     •     All orders shipped on Monday following order     •     Contains 100 mg CBD     •     All orders shipped on Monday following order     •     Contains 100 mg CBD     •     All orders shipped on Monday following order     •     Contains 100 mg CBD     •     All orders shipped on Monday following order     •     Contains 100 mg CBD     •     All orders shipped on Monday following order     •     Contains 100 mg CBD     •     All orders shipped on Monday following order     •     Contains 100 mg CBD     •     All orders shipped on Monday following order     •     Contains 100 mg CBD     •     All orders shipped on Monday following order     •     Contains 100 mg CBD     •     All orders shipped on Monday following order     •     Contains 100 mg CBD     •     All orders shipped on Monday following order     •     Contains 100 mg CBD     •     All orders shipped on Monday following order     •     Contains 100 mg CBD     •     All orders shipped on Monday following order     •     Contains 100 mg CBD     •     All orders shipped on Monday following order     •     Contains 100 mg CBD     •     All orders shipped on Monday following order     •     Contains 100 mg CBD     •     All orders shipped on Monday following order     •     Contains 100 mg CBD     •     All orders shipped on Monday following order     •     Contains 100 mg CBD     •     All orders shipped on Monday following order     •     Contains 100 mg CBD     •     All orders shipped on Monday following order     •     Contains 100 mg CBD     •     All orders shipped on Monday following order     •     Contains 100 mg CBD     •     All orders shipped on Monday following order     •     Contains 100 mg CBD     •     </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Banner'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .marquee {
    position: relative;
    /* width: 100vw; */
    /* max-width: 100%; */
    height: 40px;
    overflow: hidden;
    background-color: #11484f;
    color: #ffffff;
  }

  .track {
    position: absolute;
    white-space: nowrap;
    will-change: transform;
    animation: marquee 30s linear infinite;
  }

  @keyframes marquee {
    from { transform: translateX(0); }
    to { transform: translateX(-50%); }
  }

</style>
