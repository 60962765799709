// make sure you route the props properly to this page 
// for the homepage they are routed through Homepage.vue to ReviewSection.vue 
// for the reviews page they are routed through ... (page not done yet)

<template>
  <div v-for="review in reviews" :key="review.id" class=" col-12 col-lg-5 review-card p-5 my-3">
    <div class="d-flex justify-content-lg-between justify-content-center">
      <img :src="review.stars" alt="review stars" class="img-fluid mb-3" />
      <p class="montserrat-regular text-green d-none d-lg-block">{{ review.date }}</p>
    </div>
    <h4 class="montserrat-large text-green text-center text-lg-start">{{ review.title }}</h4>
    <p class="montserrat-regular text-green text-center text-lg-start">{{ review.text }}</p>
    <p class="montserrat-regular text-green text-center text-lg-start">- {{ review.author }}</p>

  </div>
</template>

<script>
export default {
  name: 'ReviewCard',
  props: ['reviews']
}
</script>

<style lang="scss">
.review-card {
  // max-width: 600px !important;
  background-color: rgba(211, 240, 236, 0.4);

  img {
    height: 25px;
  }
}

</style>