<template>
  <div class="cart d-flex justify-content-end">
    <div class="cart-inner p-4 d-flex flex-column align-items-center">
      <div class="upper-cart w-100">
        <!-- Cart Title -->

        <div class="title-section d-flex justify-content-between w-100 pt-4">
          <h3 class="montserrat-header text-green text-uppercase">Your Cart</h3>
          <a @click="toggleCart" class="d-flex align-items-center"><img src="../assets/cart-x.png" class="img-fluid" style="height: 20px;" /></a>
        </div>

        <!-- Products -->
        <div v-for="(item, index) in cartItems" :key="item.id">
          <div v-if="item.name !== 'Shipping'" class="product-container d-flex justify-content-between align-items-center py-4 w-100">
            <img v-if="getItemImage(item.catalog_object_id)" :src="getItemImage(item.catalog_object_id)" class="img-fluid"/>
            <!-- <img :src="getItemImage(item.catalog_object_id)" class="img-fluid"/> -->
            <div class="product-info px-3">
              <p class="product-title mb-1">{{ item.name }}</p>
              <p class="product-price mb-1">${{ formatPrice(item.base_price_money.amount) }} / {{ item.variation_name }}</p>

              <!-- <p class="product-title mb-1">{{ item.product.item_data.name }}</p>
              <p class="product-price mb-1">${{ formatPrice(item.discount) }} <span class="strikeout-red"><span class="text-green">${{ formatPrice(getVariation(item.variationId, item.product).item_variation_data.price_money.amount) }}</span></span> / {{ getVariation(item.variationId, item.product).item_variation_data.name }}</p>
              --> 
              
            </div>
            <div class="quantity-info d-flex flex-column align-items-end">
              <span class="py-2">
                <button @click="updateQuantity(item, -1)" class="quantity-button d-none d-lg-inline">-</button>
                <input :placeholder="item.quantity" :value="item.quantity" class="text-center text-green montserrat-regular"/>
                <button @click="updateQuantity(item, 1)" class="quantity-button d-none d-lg-inline">+</button>
              </span>
              
              <a @click="removeItem(item)" class="remove-btn pt-4">remove</a>
            </div>
          </div>
        </div>
      </div>

      <div class="lower-cart">
        <!-- Add A Note -->
        <div class="add-a-note w-100 pt-5">
          <p class="product-title text-center">Add a gift note to your order</p>
          <form>
            <textarea v-model="orderNote"/>
          </form>
        </div>

        <!-- Total & Checkout Button -->
        <div v-for="(item, index) in cartItems" :key="item.id">
          <div v-if="item.name == 'Shipping'" class="subtotal d-flex justify-content-between w-100 pt-4">
            <p class="product-title">Shipping:</p>
            <p class="product-title text-uppercase text-green">${{ formatPrice(item.base_price_money.amount) }}</p>
          </div>
        </div>
        <div class="subtotal d-flex justify-content-between w-100 pt-4">
          <p class="product-title text-uppercase">Subtotal:</p>
          <p class="product-title text-uppercase text-green">${{ formatPrice(this.subtotal) }}</p>
        </div>
        <!-- <div v-if="cartItems.length > 1" class="subtotal d-flex justify-content-between w-100 pt-4">
          <p class="product-title text-uppercase">Subtotal:</p>
          <p class="product-title text-uppercase text-green">${{ formatPrice(this.subtotal) }}</p>
        </div>
        <div v-else class="subtotal d-flex justify-content-between w-100 pt-4">
          <p class="product-title text-uppercase">Subtotal:</p>
          <p class="product-title text-uppercase text-green">$0.00 </p>
        </div> -->

        <div class="checkout d-flex justify-content-center">
          <a class="blaked-btn py-2" @click="sendCheckout()">Checkout</a>
        </div>
        <!-- Conditions -->
        <div class="conditions pt-5"> 
          <p class="text-center conditions-small">Still hungry? <router-link to="/collections" @click="toggleCart" class="text-underline" style="color: black;">Keep Shopping!</router-link></p>
          <p class="text-center conditions-regular">IMPORTANT: All orders are shipped the Monday after purchase. Orders placed after 8AM on Friday are shipped the following Monday</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Cart',
  props: ['toggleCart', 'updateQuantity', 'removeItem'],
  
  data() {
    return {
      quantity: 1,
      orderNote: '',
      discountPrice: ''
    }
  }, 

  computed: {
    cartItems() {
      if (this.$store.state.checkout.order && this.$store.state.checkout.order.line_items) {
        return this.$store.state.checkout.order.line_items
      }
    },

    subtotal() {
      let subtotal = (this.$store.state.checkout.order && this.$store.state.checkout.order.total_money) ? this.$store.state.checkout.order.total_money.amount - this.$store.state.checkout.order.total_tax_money.amount : 0
      return subtotal
    }
  },

  methods: {

    totalPriceDiscount() {
      this.cartItems.forEach(item => {
        // console.log('updated price test', item)
      })
    },

    getVariation(variation_id, product) {
      let variation = product.item_data.variations.find(x => x.id == variation_id )  
      
      // console.log("VARIATION IN CART: ", variation_id)

      return variation
    },

    formatPrice(num) {
      const money = num / 100
      return money.toFixed(2)
    },

    getItemImage(variantID) {
      return this.$store.getters['catalog/getCatalog'].filter(x => x.type == 'ITEM').find(x => x.item_data.variations.find(x => x.id == variantID )).image.url
    },
     // applyDiscount(item, variation) {
     // let number = variation.item_variation_data.price_money.amount

      // let percentToGet = item.discount

      // console.log('percenttoGET', percentToGet)

      // let percent = (percentToGet / 100) * number

      // return this.formatPrice(percent)

   //  } 

    sendCheckout() {
      // console.log('send to checkout page')
      let order_id = localStorage.getItem('order_id')
      // console.log(order_id)
      this.$store.dispatch('checkout/createCheckout', order_id);
    },
  },

  mounted() {
    // console.log("total price", this.totalPrice)

    // console.log(this.$store.state.checkout.order)

    // console.log(this.cartItems)
    // console.log(this.cartItems[0].uid)
    // let catalog = this.$store.getters['catalog/getCatalog'].filter(x => x.type == 'ITEM')
    // console.log('catalog', catalog)
    // console.log(catalog.find(x => x.item_data.variations.map(y => y.id).includes(this.cartItems[0].catalog_object_id)))
    // console.log(this.$store.getters['catalog/getProduct'](this.cartItems[0].uid))

    // console.log("cart items", this.cartItems)

    // this.totalPriceDiscount()
  }
}
</script>

<style lang="scss" scoped>
.cart {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 108000;

  .cart-inner {
    background-color: #ffffff;
    right: 0;
    max-width: 600px;
    width: 100%;
    overflow-y: scroll;

    // Sale
    .strikeout-red {
      color: #800000;
      text-decoration: line-through;
    }

    .product-title {
      font-family: "Montserrat";
      font-size: 12px;
      font-weight: 700;
      text-align: left;

      @media(min-width: 992px) {
        font-size: 18px;
      }
    }

    .product-price {
      color: #11484f;
      font-family: "Montserrat";
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0.38px;
      text-align: left;

      @media(min-width: 992px) {
        font-size: 18px;
      }
    }

    .product-container {
      border-bottom: 1px solid #11484f;

      img {
        max-width: 100px;
      }
    }

    .title-section {
      border-bottom: 1px solid #11484f;
    }

    .quantity-info {
      
      .quantity-button {
        border: none;
        background-color: unset;
        color: #11484f;
        font-family: "Montserrat";
        font-size: 24px;
        font-weight: 400;
        letter-spacing: 0.26px;
        text-align: center;
      }

      input {
        border: none;
        width: 50px;

        @media(min-width: 992px) {
          width: 30px;
        }
      }

      span {
        border: 1px solid #11484f;
        border-radius: 2px;
      }

      .remove-btn {
        color: #000000;
        font-family: "Montserrat";
        font-size: 12px;
        font-weight: 400;
        text-align: left;
        text-decoration: underline;

        @media(min-width: 992px) {
          font-size: 14px;
        }
      }
    }

    .lower-cart {
      // position: fixed;
      // bottom: 0;
      width: 100%;
      max-width: 600px;

      .add-a-note {

        textarea {
          width: 100%;
          height: 100px;
          border-radius: 2px;
          border: 1px solid #11484f;
        }
      }
    }
  }
}

</style>
