export default [
  {
    date: '05/06/2021',
    id: 0,
    title: 'So Good!',
    stars: require('../assets/blaked-cbd-stars.png'),
    text: 'Such good cookies. Loved the flavors so good. You won’t regret buying them. Thanks Blakery!',
    author: 'Taylor M.'
  },
  {
    date: '05/29/2021',
    id: 1,
    title: 'BUY THESE COOKIES!',
    stars: require('../assets/blaked-cbd-stars.png'),
    text: 'Best cookies I’ve had. Dunk-a-roo top cookie hands down.',
    author: 'Sara F.'
  },
  {
    date: '05/15/21',
    id: 2,
    title: 'Fresh!',
    stars: require('../assets/blaked-cbd-stars.png'),
    text: 'These were so good. I was worried about delivery making them taste less fresh but they did with only 30 seconds in the microwave',
    author: 'Bobbikae P.'
  },
  {
    date: '05/23/2021',
    id: 3,
    title: 'Incredible Cookies!',
    stars: require('../assets/blaked-cbd-stars.png'),
    text: 'These cookies are phenomenal! They are super delicious, soft, big, and just all around yummy. ',
    author: 'Leeland A.'
  }
];
