<template>
  <div class="container-fluid homepage p-0">

    <!-- Video PopUp on Page Load -->
    <div v-if="!videoHasPlayed && !isMobile" class="d-none d-md-block">
      <VideoPopUp :setVideoComplete="setVideoComplete"/>
    </div>

    <!-- Hero -->
    <HomepageHero />
    <!-- Unique Coockie Section -->
    <UniqueCard :qualityProps="qualityProps" />
    <!-- Learn & Sister Store Cards -->
    <LearnMoreCard />
    <!-- Reviews Cards -->
    <ReviewSection :reviews="reviews" />
    <!-- FAQ Drawers -->
    <FAQDrawers :faqs="faqs" />
    <!-- Social Section -->
    <SocialCard />
  </div>
</template>

<script>
import HomepageHero from '../components/HomepageHero.vue'
import UniqueCard from '../components/UniqueCard.vue'
import LearnMoreCard from '../components/LearnMoreCard.vue'
import ReviewSection from '../components/ReviewSection.vue'
import FAQDrawers from '../components/FAQDrawers.vue'
import SocialCard from '../components/SocialCard.vue'
import VideoPopUp from '../components/VideoPopUp.vue'

import qualityProps from '../data/qualityprops'
import reviews from '../data/reviews'
import faqs from '../data/faq'

export default {
  name: 'Homepage',
  components: {
    HomepageHero,
    UniqueCard,
    LearnMoreCard,
    ReviewSection,
    FAQDrawers,
    SocialCard,
    VideoPopUp
  },
  data() {
    return {
      qualityProps,
      reviews,
      faqs,
      videoHasPlayed: false, // Initial State of Video Functionality, 
    }
  },

  methods: {
    setCookie(cname, cvalue, exdays) {
      const d = new Date();
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
      let expires = 'expires=' + d.toUTCString();
      document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
    },

    getCookie(cname) {
      let name = cname + '=';
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return '';
    },

    // Video Set Cookie
    setVideoComplete() {
      this.videoHasPlayed = true;
      this.setCookie('video', 'yes', 1);
    },

  },

  mounted() {
    // console.log(qualityProps)

    if (this.getCookie('video') !== undefined) {
      this.videoHasPlayed = this.getCookie('video') == 'yes' ? true : false;
    }

    const ua = navigator.userAgent;
    if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
      console.log('mobile')
      this.isMobile = true
    } else if (navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
      navigator.userAgent &&
      navigator.userAgent.indexOf('CriOS') == -1 &&
      navigator.userAgent.indexOf('FxiOS') == -1) {
      this.isMobile = true
    } else {
      this.isMobile = false
    }
  },

}
</script>

<style scoped>

</style>
