// make sure you route the props properly to this page 
// for the homepage they are routed through Homepage.vue this page

<template>
  <div class="row faq-container py-5">
    <h2 class="text-center montserrat-header text-white my-5 d-none d-lg-block">Questions Before You Get Blaked?</h2>
    <h2 class="text-center montserrat-header text-white my-2 d-block d-lg-none">FAQ</h2>

    <div class="accordion d-flex flex-wrap justify-content-evenly mx-auto pb-5" id="accordionExample" style="max-width: 1400px;">
      <div v-for="(faq, index) in faqs" :key="faq.id" class="accordion-item col-12 col-lg-5">
        <h2 class="accordion-header" :id="'headingOne' + index">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapseOne' + index" aria-expanded="true" :aria-controls="'collapseOne' + index">
            {{ faq.question }}
          </button>
        </h2>
        <div :id="'collapseOne'+ index" class="accordion-collapse collapse" :aria-labelledby="'headingOne' + index" data-bs-parent="#accordionExample">
          <div class="accordion-body">
            {{ faq.answer }}
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'FAQDrawers',
  props: ['faqs']
}
</script>

<style lang="scss" scoped>
.faq-container {
  background-image: url('../assets/faq-background-mobile.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media(min-width: 992px) {
  background-image: url('../assets/faq-background-desktop.png');
  }

  .accordion-item {
    background-color: unset;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 1.5px solid #ffffff; 
  }
  
  .accordion-button:not(.collapsed), .accordion-button {
    color: #ffffff;
    font-family: "Montserrat";
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.26px;
    line-height: 32px;
    text-align: left;
    background-color: unset;
  }

  .accordion-body {
    color: #ffffff;
    font-family: "Montserrat";
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.23px;
    line-height: 24px;
    text-align: left;
  }

  .accordion-button:focus {
    border: none;
    box-shadow: none;
  }

  .accordion-button::after {
    background-image: url("../assets/white-plus.svg");
  }

  .accordion-button:not(.collapsed)::after {
    background-image: url("../assets/white-minus.svg");
  }
}

</style>