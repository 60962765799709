<template>
  <div class="header row w-100 px-0 mx-0">
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <div class="container-fluid p-0">
        <div class="d-flex d-lg-none justify-content-between align-items-center w-100">
        <button id="navbar-toggler" class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <!-- Mobile Logo -->
        <span class="d-flex d-lg-none justify-content-between">
          <router-link to="/"><img src="../assets/get-blaked-logo.png" alt="get blaked logo" class="logo" /></router-link>
        </span>
        <!-- Mobile Cart -->
        <span class="d-block d-lg-none cart-container" @click.prevent ="() => toggleCart('buttonTrigger')">
          <img src="../assets/blaked-cart.png" alt="cart icon"  class="cart-icon mx-3"/>
          <span v-if="cartItemTotal >= 1" class="cart-amount-icon">{{ cartItemTotal }}</span>
        </span>

        </div>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mx-auto me-auto mb-2 mb-lg-0 d-flex justify-content-between w-100">
            <!-- Shop -->
              <li class="nav-item">
                <router-link to="/collections" @click.prevent="() => toggleNav()" class="nav-link text-green montserrat-bold">Shop</router-link>
              </li>
              <!-- Our Story -->
              <li class="nav-item">
                <router-link to="/about" @click.prevent="() => toggleNav()" class="nav-link text-green montserrat-bold">Our Story</router-link>
              </li>
              <!-- Logo -->
              <li class="nav-item active d-none d-lg-block">
                <router-link to="/" @click.prevent="() => toggleNav()" aria-current="page" class="nav-link active"><img src="../assets/get-blaked-logo.png" alt="get blaked logo" class="logo" /></router-link>
              </li>
              <!-- Reviews -->
              <li class="nav-item">
                <!-- <router-link to="/reviews" class="nav-link text-green montserrat-bold">Reviews</router-link> -->
                <a href="/#reviews-section" @click="() => toggleNav()" class="nav-link text-green montserrat-bold">Reviews</a>
              </li>
              <!-- FAQs -->
              <li class="nav-item">
                <router-link to="/faq" @click.prevent="() => toggleNav()" class="nav-link text-green montserrat-bold">FAQS</router-link>
              </li>
          </ul>
          
        </div>
        
        
      </div>
      <!-- Desktop Cart -->
      <span class="d-none d-lg-block cart-container" @click.prevent ="() => toggleCart('buttonTrigger')">
        <img src="../assets/blaked-cart.png" alt="cart icon" class="cart-icon mx-3" />
        <span v-if="cartItemTotal >= 1" class="cart-amount-icon">{{ cartItemTotal }}</span>
      </span>
      
    </nav>
  </div>
</template>

<script>
export default {
  name: 'Header',
  props: ['toggleCart', 'cartItems'],
  
  computed: {
    cartItemTotal() {
      return this.$store.getters['checkout/cartItemTotal'] - 1
    }
  },
  
  methods: {
    toggleNav() {
      document.querySelector('#navbar-toggler').click();
    }
  }
}
</script>

// Add "scoped" attribute to limit CSS to this component only
<style scoped lang="scss">
.navbar {
  box-shadow: 5px 2px 15px rgba(136, 181, 186, 0.5);
  background-color: #ffffff;
}

.navbar-nav {
  max-width: 1000px;
}

@media(min-width: 992px){
  .logo {
    width: 225px !important;
  }
}

.logo {
    width: 150px;
  }

.cart-container {
  position: relative;
  cursor: pointer;

  .cart-icon {
    /* height: 25px; */
    width: 35px;
  }

  .cart-amount-icon {
    position: absolute;
    top: 2px;
    left: 50%;
    color: #ffffff;
  }

}

a {
  text-decoration: none;
}

.navbar-toggler {
  border: none;
}

.navbar-toggler-icon {
    background-image: url("../assets/blaked-hamburger-icon.png") !important;
}
</style>
