import store from '@/store'
import axios from 'axios'

const http = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 0
})

// http.CancelToken = axios.CancelToken
// http.isCancel = axios.isCancel

http.interceptors.request.use((config) => {
	let token = store.state.token
  let domain = store.state.domain

	if (token) {
    config.headers['X-Udundi-Access-Token'] = token
  }

  if (domain) {
    config.params = { domain }
  }

	return config
  }, (error) => {
    return Promise.reject(error)
  }
)

export default http
