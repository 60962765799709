<template>
  <div class="popup d-flex align-items-center justify-content-center">
    <div class="popup-inner p-5 d-flex flex-column align-items-center">
      <h1 class="montserrat-hero text-green text-uppercase pt-5 pb-4 mt-5 mx-lg-5 px-lg-5" style="white-space: nowrap;">Please Verify <br>Your Age</h1>
      <button class="blaked-btn py-2 popup-close" @click.prevent="setConsent">I am 18 or older</button>
      <a href="https://www.google.com/" class="text-green pb-5 pt-4 mb-5 leave-site-text">Leave Site</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PopUp',
  props: ['setConsent']
}
</script>

<style lang="scss" scoped>
.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.6);

  .popup-inner {
    background-image: url('../assets/popup-background-mobile-nobite.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @media(min-width: 992px) {
      background-image: url('../assets/popup-background-desktop-nobite.png');
    }

    .leave-site-text {
      font-family: "Montserrat";
      font-size: 12px;
      font-weight: 700;
      letter-spacing: 0.17px;
      text-align: center;
      text-decoration: underline;

      @media(min-width: 992px) {
        font-size: 18px;
      }
    }
  }
}

</style>