<template>
  <div class="row learn-more-container">
    <div class="col-12 col-lg-6 learn-more d-flex flex-column p-5 justify-content-center align-items-center">
      <h2 class="text-center montserrat-header text-white pt-5">Learn your CBD's</h2>
      <p class="text-center montserrat-regular text-white my-3 mx-auto">Learn more about CBD with answers to commonly asked questions like… What are the uses for CBD? and Will CBD get me high?</p>
      <p class="text-center  montserrat-regular text-white my-3 mx-auto">Plus, find out more about our process for creating Get Blaked cookies, and download our CBD labsheets.</p>
      <router-link to="/faq" class="blaked-btn-white py-2 mb-5 mt-4">Learn More</router-link>
    </div>
    <div class="col-12 col-lg-6 sister-store d-flex flex-column p-5 justify-content-center align-items-center">
      <img src="../assets/blakery_logo.png" class="blakery-logo mt-lg-5 mb-3" alt="blakery logo" />
      <h2 class="text-center montserrat-header">Visit Our Sister Store</h2>
      <p class="text-center montserrat-regular my-3 mx-auto">If you’re looking for more delicious cookies (without the CBD), check out The Blakery’s online store and mix and match flavors in your own custom box!</p>
      <a @click="openInNewTab('https://www.theblakeryli.com/')" class="blaked-btn py-2 mb-5 mt-4">Shop The Blakery</a>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'LearnMoreCard',
    methods: {
      openInNewTab(url) {
        window.open(url, '_blank').focus();
        }
    }
  }
</script>

<style lang="scss">

.learn-more-container {

  p {
    max-width: 700px;
  }

  .learn-more {
    background-image: url('../assets/cbd-background.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .sister-store {
    background-image: url('../assets/blakery-background-mobile.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @media(min-width: 992px) {
    background-image: url('../assets/blakery-background.png');
    }

    img {
      width: 100px;
    }
  }
}

</style>