import { createWebHistory, createRouter } from 'vue-router'
import store from '../store'

const Homepage = () => import('@/views/Homepage.vue')
const Shop = () => import('@/views/Shop.vue')
const ProductPage = () => import('@/views/ProductPage.vue')
const About = () => import('@/views/About.vue')
const Reviews = () => import('@/views/Reviews.vue')
const FAQ = () => import('@/views/FAQ.vue')
const Contact = () => import('@/views/Contact.vue')
const TermsConditions = () => import('@/components/TermsConditions.vue')
const Confirmation = () => import('@/views/Confirmation.vue')

const history = createWebHistory()

const guardRoute = async (to, from, next) => {
  // if (this.getCatalog.length != 0) {
  //   next()
  // } else if (this.getCatalog.length == 0) {
  //   console.log('error')
  // }
  // console.log('test guardRoute')// 
  // if (catalogStore.getters.getCatalog) {
  //   next()
  // } else {
  //   console.log('error')
  // }

  // if (to.path == '/product/:id') {
  //   catalogStore.actions.fetchCatalog()
  //   next()
  // }

  // console.log(store.getters['catalog/getCatalog']);
  if (store.getters['catalog/getCatalog'].length !== 0) {
    // console.log('does not = 0')
    next()
  } else {
    // console.log('before await')
    await store.dispatch('catalog/fetchCatalog')
    // console.log('await catalog fetch')
    next()
  }
  
}

const router = createRouter({
  history,
  routes: [
    {
      path: '/',
      name: 'Homepage',
      component: Homepage
    },
    {
      path: '/collections',
      name: 'Collections',
      component: Shop
    },
    {
      path: '/product/:id',
      name: 'Product',
      component: ProductPage,
      beforeEnter: guardRoute,
      props: true
    },
    {
      path: '/about',
      name: 'About',
      component: About
    },
    {
      path: '/reviews',
      name: 'Reviews',
      component: Reviews
    },
    {
      path: '/faq',
      name: 'FAQ',
      component: FAQ
    },
    {
      path: '/contact',
      name: 'Contact',
      component: Contact
    },
    {
      path: '/terms-and-conditions',
      name: 'TermsConditions',
      component: TermsConditions
    },
    {
      path: '/confirmation',
      name: 'Confirmation',
      component: Confirmation
    },
  ],
  scrollBehavior() {
    document.getElementById('app').scrollIntoView();
  }
})


export default router

