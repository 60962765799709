// Homepage FAQ / Section FAQ
export default [
  {
    id: 0,
    question: 'When will my order ship out? IMPORTANT!',
    answer: 'We ship out orders every Monday. YOU MUST PLACE YOUR ORDER BY 8 A.M. FRIDAY FOR SHIPPING THAT MONDAY. If you place your order after this time, it will ship the following week. We are still growing and need time to hand craft each cookie!!! Once the cookies are shipped from our facility, they are in the hands of USPS and we are not responsible for any delays or lost packages. PLEASE BE SURE TO INPUT THE PROPER ADDRESS BEFORE PLACING YOUR ORDER AS THIS CANNOT BE CHANGED ONCE YOUR ORDER IS PLACED!'
  },
  {
    id: 1,
    question: 'Do you have a physical location?',
    answer: 'We are currently an ecommerce store only. For updates, check out our Facebook and Instagram pages.'
  },
  {
    id: 2,
    question: 'What if I have allergies?',
    answer: 'All confections are processed in a kitchen that uses peanuts, tree nuts, wheat, eggs, milk, and soy.'
  },
  {
    id: 3,
    question: 'What is your returns and refunds policy?',
    answer: 'At this time, we cannot accept returns or exchanges, and all purchases are non-refundable. Once an order is placed, we cannot cancel your order as this disrupts the fulfilment process.'
  },
  {
    id: 4,
    question: 'What is the best way to eat the cookies?',
    answer: 'The cookies are incredible right out of the package, however popping each cookie in the microwave for ~30 seconds puts them over the top!'
  },
  {
    id: 5,
    question: 'How long do the cookies last?',
    answer: '"Blaked" cookies are baked fresh to order and we recommend eating upon arrival for optimal freshness. Because we do not use preservatives or additives, they can stay uneaten for 4-5 days if stored at room temperature. If you’d like to enjoy your cookie after day 4, we recommend storing in the freezer and thawing beforehand.'
  }
];


// FAQ Page 

const faqArray = [
  {
    id: 0,
    question: 'Why do people take CBD?',
    a1: 'Some people take CBD because they need support for specific reasons while others want to enhance their overall well-being. To understand the benefits of CBD, you need to understand what it does in the body.',
    a2: 'CBD is a type of compound called a cannabinoid. Cannabinoids are abundant in hemp and work with a series of receptors in your body known as the endocannabinoid system (ECS). Studies show the ECS is involved in a lot of important jobs. These jobs include regulating your anti-inflammatory response, immune system, appetite, mood, anxiety levels, sleep schedule and more. Basically, the ECS helps keep your body in homeostasis.',
    hasImage: false
  },
  {
    id: 1,
    question: 'What is Ultra Broad Spectrum CBD?',
    a1: 'The answer: Basically, this extract type is made with CBD and other cannabinoids and aromatic compounds known as terpenes.',
    a2: 'Why does this matter? It has to do with a phenomenon known as the entourage effect, which is when multiple cannabinoids and terpenes synergize in your body for greater wellness potential. The entourage effect makes sense in terms of nature. After all, cannabidiol is never an isolated powder by itself in the wild. It’s always present in a hemp plant together with aromatic terpenes, and other cannabinoids like cannabigerol, (CBG) cannabinol (CBN) and cannabichromene (CBC).',
    a3: 'Our cookies are made with Ultra Broad spectrum CBD products that contain cannabidiol and a broad range of other cannabinoids. These products have non-detectable levels of THC, which is a scientifically accurate way of saying they have essentially none. You get the full benefits of the plant, without the effects of THC. The broad range of cannabinoids is intended to offer you the entourage effect.',
    hasImage: true,
    image: require('../assets/cbd-graph.png')
  },
  {
    id: 2,
    question: 'Will CBD get me high? ',
    a1: 'The short answer to the question ‘will CBD get me high?’ is no. CBD is non-intoxicating and will not get you high. We mentioned above that hemp plants have over one hundred types of compounds in them. One of these is THC (tetrahydrocannabinol) which is the compound that causes the high associated with cannabis. Green Roads’ CBD products are made with cannabidiol from hemp plants that meet the legal qualifications for industrial hemp. One qualification is that the plant contains less than 0.3% THC by dry weight. This minimal amount is already not enough to cause a high, hence the legality of industrial hemp.',
    hasImage: false
  }
];

export { faqArray }