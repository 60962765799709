<template>
  <div id="reviews-section" class="row review-section mx-auto d-flex justify-content-evenly pb-5">
    <h2 id="review-header" class="text-center montserrat-header text-green my-5 d-none d-lg-block">Happy, Blaked customers</h2>
    <h2 id="review-header" class="text-center montserrat-header text-green mb-3 mt-5 d-block d-lg-none">Happy, Blaked fans</h2>
    <ReviewCard :reviews="reviews" />
  </div>
</template>

<script>
import ReviewCard from './ReviewCard'

export default {
  name: 'ReviewSection',
  components: {
    ReviewCard
  },
  props: ['reviews']
}
</script>

<style lang="scss">
.review-section {
  max-width: 1400px;
}

</style>