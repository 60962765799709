<template>
  <div class="video-popup">
    <video id="video" width="100vw" height="100vh" preload="auto" muted src="../assets/video.mp4" @ended.prevent="setVideoComplete"></video>
  </div>
</template>

<script>
export default {
  name: 'VideoPopUp',
  props: ['setVideoComplete'],

  methods: {
    playVideo() {
      const video = document.querySelector('#video')
      video.play()
    }
  },

  mounted() {
    this.playVideo()
  }
}
</script>

<style lang="scss" scoped>

.video-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;

  video {
    height: auto;
    width: 100vw;
  }
}

</style>