<template>
  <div class="row quality-props-container mx-auto py-5 mx-auto">
    <h2 class="text-center montserrat-header text-green mb-5 d-none d-md-block">What makes a GETBLAKED cookie unique</h2>
    <h2 class="text-center montserrat-header text-green mb-5 d-block d-md-none">Why are GETBLAKED cookies unique?</h2>
    <div v-for="quality in qualityProps" :key="quality.id" class="col-6 col-lg-3 d-flex flex-column justify-content-start align-items-center">
      <img :src="quality.imageUrl" class="quality-icon"/>
      <h2 class="text-center montserrat-bold text-green py-3">{{ quality.text }}</h2>
    </div>
    <router-link to="/collections" class="blaked-btn py-2 d-block d-lg-none mx-auto mt-5">Shop Now</router-link>
  </div>
</template>

<script>
export default {
  name: 'UniqueCard',
  props: ['qualityProps']
}
</script>

<style lang="scss">
.quality-props-container {
  max-width: 600px;

  @media(min-width: 992px) {
    max-width: 1400px;
  }

  .blaked-btn {
    max-width: 300px;
  }

  .quality-icon {
    width: 100px;
    height: 100px;

    @media(min-width: 992px) {
      width: 150px;
      height: 150px;
    }
  }
}

</style>