export default [
  {
    text: 'Quality Ingredients you can Trust',
    id: 0,
    imageUrl: require('../assets/blaked-ingredients.svg'),
  },
  {
    text: 'Pure Decadence',
    id: 1,
    imageUrl: require('../assets/blaked-decadence.svg'),
  },
  {
    text: '100 mg CBD ultra broad spectrum distillate',
    id: 2,
    imageUrl: require('../assets/blaked-cbd-leaf.svg'),
  },
  {
    text: '“Blaked” to Order',
    id: 3,
    imageUrl: require('../assets/blaked-baked-order.svg'),
  }
];
