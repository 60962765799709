<template>
  <div class="row">
    <!-- desktop photo grid -->
    <div class="gallery col-12 p-0 d-none d-lg-grid">
      <figure class="gallery__item gallery__item--1">
        <img src="../assets/cookie1.jpg" class="gallery__img" alt="Image 1">
      </figure>
      <figure class="gallery__item gallery__item--2">
        <img src="../assets/cookie2.jpg" class="gallery__img" alt="Image 2">
      </figure>
      <figure class="gallery__item gallery__item--3">
        <img src="../assets/cookie3.jpg" class="gallery__img" alt="Image 3">
      </figure>
      <figure class="gallery__item gallery__item--4">
        <img src="../assets/cookie4.jpg" class="gallery__img" alt="Image 4">
      </figure>
      <figure class="gallery__item gallery__item--5 d-flex flex-column px-3 justify-content-center">
        <h3 class="text-white montserrat-header text-start" alt="social media information">Follow Us!</h3> 
        <p class="text-white montserrat-small">@getblaked_</p>
        <div class="social-icons d-flex">
          <a target="blank_" href="https://www.instagram.com/getblaked_/"><img src="../assets/insta-white.png" alt="instagram"/></a>
          <a target="blank_" href="https://www.facebook.com/getblaked/"><img src="../assets/fb-white.png" alt="facebook" class="mx-3" /></a>
        </div>
      </figure>
      <figure class="gallery__item gallery__item--6">
        <img src="../assets/cookie5.jpg" class="gallery__img" alt="Image 6">
      </figure>
    </div>
    <!-- mobile photo grid -->
    <div class="col-6 p-0 d-block d-lg-none">
      <img src="../assets/cookie1.jpg" class="gallery__img" alt="Image 1">
    </div>
    <div class="col-6 p-0 d-block d-lg-none">
      <img src="../assets/cookie3.jpg" class="gallery__img" alt="Image 2">
    </div>
    <div class="col-12 px-0 py-4 d-flex flex-column justify-content-center align-items-center mobile-social d-block d-lg-none">
      <h3 class="text-white montserrat-header text-start" alt="social media information">Follow Us!</h3>
        <p class="text-white montserrat-small">@getblaked_</p>
        <div class="social-icons d-flex">
          <a href="https://www.instagram.com/getblaked_/"><img src="../assets/insta-white.png" alt="instagram"/></a>
          <a href="https://www.facebook.com/getblaked/"><img src="../assets/fb-white.png" alt="facebook" class="mx-3" /></a>
        </div>
    </div>
    <div class="col-6 p-0 d-block d-lg-none">
      <img src="../assets/cookie4.jpg" class="gallery__img" alt="Image 4">
    </div>
    <div class="col-6 p-0 d-block d-lg-none">
      <img src="../assets/cookie5.jpg" class="gallery__img" alt="Image 6">
    </div>
  </div>
</template>

<script>

export default {
  name: 'SocialCard'
}
</script>

<style lang="scss" scoped>
.gallery {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(8, 5vw);
  grid-gap: 0;
}

.gallery__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin-bottom: 0;
}

.gallery__item--1 {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 9;
  margin-bottom: 0;
}

.gallery__item--2 {
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 1;
  grid-row-end: 6;
  margin-bottom: 0;
}

.gallery__item--3 {
  grid-column-start: 5;
  grid-column-end: 7;
  grid-row-start: 1;
  grid-row-end: 6;
  margin-bottom: 0;
}

.gallery__item--4 {
  grid-column-start: 7;
  grid-column-end: 9;
  grid-row-start: 1;
  grid-row-end: 6;
  margin-bottom: 0;
}

.gallery__item--5 {
  background-color: #11484f;
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 6;
  grid-row-end: 9;
  margin-bottom: 0;
}

.gallery__item--6 {
  grid-column-start: 5;
  grid-column-end: 9;
  grid-row-start: 6;
  grid-row-end: 9;
  margin-bottom: 0;
}

.social-icons {

  img {
    height: 30px;

    @media(min-width: 992px) {
      height: 50px;
    }
  }
}

.mobile-social {
  background-color: #11484f;
}

.col-6 {
  img {
    height: 300px;
  }
}

</style>
