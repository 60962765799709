import { getCatalogList } from "@/services/catalog"

const state = {
  catalog: []
}

const getters = {
  getCatalog(state) {
    return state.catalog
  },

  getProduct: (state) => (id) => {
	  console.log('getProduct Getter: ', state.catalog.find(product => product.id == id));
	  return state.catalog.find(product => product.id == id) ? state.catalog.find(product => product.id == id) : false;
  }
}

const actions = {
	async fetchCatalog({ commit }) {
		try {
			const response = await getCatalogList();
			commit('SET_CATALOG', response.objects);
			console.log('fetchcatalog done')
		} catch (error) {
				// handle the error here
			console.log("ERROR: ", error)
			return error
		}
	}
}

const mutations = {
	SET_CATALOG(state, data) {
		state.catalog = data;
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
