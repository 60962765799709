<template>
  <div class="container-fluid">
    <div class="sticky row" style="position: sticky; top: 0; z-index: 20000;">
      <!-- Banner -->
      <Banner />

      <!-- Header -->
      <Header :cartItems="this.cartItems" :toggleCart="toggleCart" />
    </div>

    <!-- Router / Current Page -->
    <div id="PageContainer">
      <router-view :addToCart="addToCart" :getCatalog="getCatalog" :catalogList="catalogList" />
    </div>

    <!-- 18 Year Old PopUp -->
    <div v-if="!consent">
      <PopUp :setConsent="setConsent"/>
    </div>

    <!-- Video PopUp on Page Load
    <div v-if="!videoHasPlayed">
      <VideoPopUp :setVideoComplete="setVideoComplete"/>
    </div> -->

    <!-- Cart -->
    <div v-if="cartTrigger.buttonTrigger">
      <Cart :toggleCart="() => toggleCart('buttonTrigger')" :updateQuantity="updateQuantity" :removeItem="removeItem" />
        <!-- <Cart :toggleCart="() => toggleCart('buttonTrigger')" :updateQuantity="updateQuantity" :updatePrice="updatePrice" :removeItem="removeItem" :totalPrice="this.totalPrice" /> -->
    </div>

    <!-- Footer -->
    <Footer />

  </div>
  
</template>

<script>

import Banner from './components/Banner.vue'
import Header from './components/Header.vue'
import Homepage from './views/Homepage.vue'
import Footer from './components/Footer.vue'
import PopUp from './components/PopUp.vue'
import Cart from './components/Cart.vue'
import VideoPopUp from './components/VideoPopUp.vue'

/* External API Calls for Looks and Products */
import { getCatalogList } from '@/services/catalog'

/* Vue imports */
import { mapState, mapGetters, mapActions } from 'vuex'

import { ref } from 'vue'


export default {
  name: 'App',
  components: {
    Banner,
    Header,
    Homepage,
    Footer,
    PopUp,
    Cart
    // VideoPopUp
  },

  data() {
    return {
      consent: false, // Initial State of Cookie Functionality
      // cartItems: [],
      totalPrice: 0,
      videoHasPlayed: false, // Initial State of Video Functionality
      catalogList: [],
      discountInitial: ''
    }
  },

  // Cart Open and Close 
  setup() {
    const cartTrigger = ref({
      buttonTrigger: false
    })

    const toggleCart = (trigger) => {
      // console.log('open cart...')
      cartTrigger.value[trigger] = !cartTrigger.value[trigger]
    }

    return {
      cartTrigger,
      toggleCart
    }
  },

  computed: {
    ...mapGetters('catalog', ['getCatalog']),
  },

  // Set Cookie
  methods: {

    ...mapActions('catalog', ['fetchCatalog']),

    setCookie(cname, cvalue, exdays) {
      const d = new Date();
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
      let expires = 'expires=' + d.toUTCString();
      document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
    },

    getCookie(cname) {
      let name = cname + '=';
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return '';
    },

    // 18 Years Up Set Cookie
    setConsent() {
      this.consent = true;
      this.setCookie('consent', 'yes', 30);
    },

    // // Video Set Cookie
    // setVideoComplete() {
    //   this.videoHasPlayed = true;
    //   this.setCookie('video', 'yes', 30);
    //   console.log('ended video')
    // },

    // Add to Cart
    addToCart(variationId, product = {}, quantity = 1, price, image, discount) {

      // Shipping as product -- adds shipping to line items on the first item being added to cart
      // if (this.$store.state.checkout.order == null) {

      //   this.catalogList.forEach(item => {
      //     if (item.type == 'ITEM' && item.item_data.name == 'Shipping') {
      //       let lineItem = {
      //         "variationId": item.item_data.variations[0].id,
      //         "quantity": '1',
      //         "product": item, 
      //         "price": item.item_data.variations[0].item_variation_data.price_money.amount,
      //         "image": null,
      //         "discount": undefined
      //       }

      //       this.$store.dispatch('checkout/addLineItem', lineItem);
      //     }
      //   })
      // }

      console.log(this.catalogList)


      if (variationId.type == 'ITEM_VARIATION') {
        price = variationId.item_variation_data.price_money.amount
        variationId = variationId.id
        } else {
        let variationArray = product.item_data.variations

        variationArray.forEach(variation => {
          if (variation.id == variationId) {
            variationId = variation.id
            price = variation.item_variation_data.price_money.amount
            //discount price
            discount = (discount / 100) * price
          }
        })
      }

      let lineItem = {
        "variationId": variationId,
        "quantity": quantity,
        "product": product,
        "price": price, 
        "image": image,
        "discount": discount
      }

      this.$store.dispatch('checkout/addLineItem', lineItem);

      this.toggleCart('buttonTrigger');
    },

    updateQuantity(lineItem, value) {
      let quantity = String(Number(lineItem.quantity) + value)

      if (quantity != 0) {
        this.$store.dispatch('checkout/updateOrder', { uid: lineItem.uid, 'quantity': quantity })
      }
    },

    removeItem(lineItem) {
      this.$store.dispatch('checkout/removeLineItem', lineItem)
    },

    formatPrice(num) {
      const money = num / 100
      return money.toFixed(2)
    }
  },

  // 18 Years Check and Video on Page Load
  mounted() {
    if (this.getCookie('consent') !== undefined) {
      this.consent = this.getCookie('consent') == 'yes' ? true : false
    }

    // if (this.getCookie('video') !== undefined) {
    //   this.videoHasPlayed = this.getGookie('video') == 'yes' ? true : false
    // }
  },

  async created() {
    let order_id = localStorage.getItem('order_id');

    if (order_id) {
      this.$store.dispatch('checkout/getOrder', order_id);
    }

    if (this.getCatalog.length === 0) {
      await this.fetchCatalog()

      // console.log("CATALOG FETCHED: ", this.getCatalog)

      //we want to assign images to their products
      let catalogList = this.getCatalog
      this.catalogList = catalogList

      // For Discount
      let discount

      catalogList.forEach(item => {
        if(item.type == 'DISCOUNT' && item.discount_data.name == 'Black Friday Sale') {
          discount = item.discount_data.percentage
          return discount
        }
      })

      catalogList.forEach(item => {
        if (item.type == 'ITEM' ) {
          item.discount = discount
          // console.log('item.discount', item.discount)
          // console.log('item.DISC', discount)
        }
      })

      // For collections radio buttons
      catalogList.forEach(item => {

        // console.log('LOADED FROM APP.VUE', this.$store.state.checkout.order.line_items)

        if (item.type == 'ITEM' ) {
          item.selected = item.item_data.variations[1]

          // OG price 
          item.selectedPrice = item.selected.item_variation_data.price_money.amount 


          // let number = item.selected.item_variation_data.price_money.amount
          // let percentToGet = item.discount
          // let percent = (percentToGet / 100) * number

          // item.selectedPrice = this.formatPrice(percent)

        }
      })

      // console.log('CATALOG LIST', this.catalogList)
		}

  }

}
</script>

<style lang="scss">
#app {
  a:hover {
    cursor: pointer;
  }
  #PageContainer {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
</style>
