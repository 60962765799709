//import { createStore } from 'vuex'
import Vuex from 'vuex'
// import _ from 'underscore'
//import { Auth } from '@/services/auth'

import catalog from '@/store/modules/catalog.store'
import checkout from '@/store/modules/checkout.store'

export default new Vuex.Store({
  modules: {
    catalog,
    checkout
  },
  state: {
    token: null,
    domain: null,
    //shopify: null,
  },
  getters: {
    auth: state => state.token !== null,
    // published: (state) => {
    //   return _.findWhere(state.themes, { role: 'main' })
    // }
  },
  mutations: {
   setToken: (state, token) => {
      state.token = token
   },
   setDomain: (state, domain) => {
      state.domain = domain
   },
  //  setShopify: (state, shopify) => {
  //     state.shopify = shopify
  //  },

   setCatalog: (state, data) => {
      state.catalog = (data) ? data.catalog : null
   }

  },
  actions: {
    // shopify: async ({ commit }, domain) => {
    //   commit('setShopify', await Auth.getAccount(domain))
    // },
    
    catalog: async ({ commit }, domain) => {
      commit('setCatalog', await Catalog.getCatalog(domain))
    },



  }
})

// export default createStore({
//   state: {
//   },
//   mutations: {
//   },
//   actions: {
//   },
//   modules: {
//   }
// })
