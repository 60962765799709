import http from '@/services'

export const Catalog = {
  getCatalogList,
  // getCatalogDiscounts
}

async function getCatalogList () {
	try {
    let { data } = await http.get('https://api.getblaked.com/api/catalog/list?types=item%2Cimage')
    // let { data } = await http.get('http://localhost:3000/api/catalog/list?types=item%2Cimage%2Cdiscount')
    
    let testDiscount

    data.objects.forEach((item) => {
      
      if(item.type == "ITEM" && item.image_id !== undefined) {
        let image = data.objects.find(x => x.id == item.image_id);
        item.image = image ? image.image_data : null;
      }

      // if (item.type == "ITEM") {
        
      // }

      // if(item.type == 'DISCOUNT' && item.discount_data.name == 'Black Friday Sale') {
      //   testDiscount = item.discount_data.percentage

      //   console.log('testDiscount', testDiscount)

      //   if (item.type == "ITEM") {
      //     item.discount = testDiscount
      //   }
      // }

    });

    return data
	} catch (err) {
		console.log('error: ', err)
	}
}


// async function getCatalogDiscounts() {
//   try {
//     let { data } = await http.get('http://localhost:3000/api/catalog/list?types=discount')
//     return data
//   } catch (err) {
//     console.log('error: ', err)
//   }
// }

export {
  getCatalogList,
  //getCatalogDiscounts
}
