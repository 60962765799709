<template>
    <div class="footer row pt-5">
      <div class="col-12 d-flex flex-column flex-lg-row mx-auto" style="max-width: 1400px;">
        <div class="col-12 col-lg-6 pb-lg-5">
          <!-- Logo -->
          <router-link to="/#homepage"><img src="../assets/get-blaked-logo.png" alt="get blaked logo" class="logo pb-3" /></router-link>
          <!-- Navigation -->
          <ul class="p-1">
            <li class="py-2"><router-link to="/contact" class="text-green work-sans-small">Contact</router-link></li>
            <li class="py-2"><router-link to="/collections" class="text-green work-sans-small">Shop</router-link></li>
            <li class="py-2"><router-link to="/about" class="text-green work-sans-small">Our Story</router-link></li>
            <li class="py-2"><router-link to="/terms-and-conditions" class="text-green work-sans-small">Terms & Conditions</router-link></li>
            <li class="py-2"><router-link to="/faq" class="text-green work-sans-small">FAQS</router-link></li>
            <!-- <li class="py-2"><router-link to="/reviews" class="text-green work-sans-small">Reviews</router-link></li> -->
          </ul>
          <!-- Social Media -->
          <div class="social-media mb-3">
            <a target="blank_" href="https://www.instagram.com/getblaked_/"><img src="../assets/blaked-insta-logo.png" alt="instagram" class="social-icon" /></a>
            <a target="blank_" href="https://www.facebook.com/getblaked/"><img src="../assets/blaked-fb-logo.png" alt="facebook" class="social-icon mx-3" /></a>
          </div>
        </div>
        <div class="col-12 col-lg-6 pb-5 px-lg-3 d-flex flex-column justify-content-center align-items-center align-items-lg-start">
          <!-- Email Sign Up -->
          <h4 class="montserrat-medium text-green">Let's be best buds!</h4>
          <div class="d-flex flex-column flex-xl-row align-items-xl-center align-items-start">
            <input type="email" placeholder="Enter Your Email Addess" class="work-sans-small text-center py-3 h-100"/>
            <a class="blaked-btn text-white px-5 py-2 mt-4 mt-xl-0">Join Now</a>
          </div>
        </div>
      </div>
      <div class="col-12 disclaimer p-4">
        <!-- Disclaimer -->
        <p class="montserrat-small text-center mx-auto" style="max-width: 1400px;">The statements made regarding these products have not been evaluated by the Food and Drug Administration. The efficacy of these products has not been confirmed by FDA-approved research. These products are not intended to diagnose, treat, cure or prevent any disease. All information presented here is not meant as a substitute for or alternative to information from health care practitioners. Please consult your health care professional about potential interactions or other possible complications before using any product.</p>
      </div>
    </div>
</template>

<script>
export default {
  name: 'Footer',
  props: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.footer {
  /* position: fixed; */
  bottom: 0;
}

.logo {
  width: 225px;
}

a {
  text-decoration: none;
}

ul {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
}

li {
  list-style-type: none;
}

.social-icon {
  width: 30px;
}

input {
  width: 280px;
  margin-right: 0px;
  border: 2px solid #11484f;

  @media(min-width: 1200px) {
    margin-right: 15px;
  }
}

input::placeholder {
  color: #11484f;
}

.email-text {
  color: #11484f;
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0.23px;
  line-height: 32px;
  text-align: center;
  text-transform: uppercase;
}

.disclaimer {
  background-color: rgba(211, 240, 236, 0.4);
}
</style>
